

const CompanyEmail = ({payload, setPayload}: any) => {
    const onBlur = (e: any) => {
        setPayload({...payload, company_email: e.target.value})
    }

    return (
        <div>
            <div className="InputLabel">Company Email <span style={{fontSize: '11px', float: 'right'}}>*Required</span></div>
            <input type="email" placeholder="Enter Company Email" className="FormInput" onBlur={onBlur}/>
        </div>
    )
}

export default CompanyEmail