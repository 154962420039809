

const CompanyAddress = ({payload, setPayload}: any) => {
    const onBlur = (e: any) => {
        setPayload({...payload, company_address: e.target.value})
    }
    return (
        <div>
            <div className="InputLabel">Company Address</div>
            <input type="text" placeholder="Enter Company Address" className="FormInput" onBlur={onBlur}/>
        </div>
    )
}

export default CompanyAddress