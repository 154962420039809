

type HeaderBarLinkProps = {
    title: string;
    onClick: any;
    isMobile?: boolean;
}

const HeaderBarLink = (props: HeaderBarLinkProps) => {

    return (
        <div className={`HeaderBarMenuItem ${props.isMobile && 'MobileBarMenuItem'}`}>
            <button onClick={props.onClick} className={`HeaderBarButton`}>{props.title}</button>
        </div>
    )
}



type HeaderBarProps = {
    setActiveForm: any;
    activeForm: boolean;
}

const HeaderBar = (props: HeaderBarProps) => {
    return (
        <div className="HeaderBar">
            <div className="BarLeftContainer">
                <div className="BarLeft">
                    <img src="/img/logo.png" className="HeaderBarLogo" onClick={() => props.setActiveForm(false)} />
                </div>
            </div>
            <div className="BarRightContainer">
                <div className="BarRight">
                    <HeaderBarLink title={'About'} onClick={() => {}} />
                    <HeaderBarLink title={'Product'} onClick={() => {}} />
                    <HeaderBarLink title={'Team'} onClick={() => {}} />
                    <HeaderBarLink title={'News'} onClick={() => {}} />
                    <HeaderBarLink title={'Contact'} onClick={() => props.setActiveForm(!props.activeForm)} isMobile />
                </div>
            </div>
        </div>
    )
}

export default HeaderBar