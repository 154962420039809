import { Name } from "./Name"
import { Title } from "./Title"
import { CompanyName } from "./CompanyName"
import { CompanyEmail } from "./CompanyEmail"
import { CompanyAddress } from './CompanyAddress'
import { Message } from './Message'
import { useEffect, useState } from "react"

type ContactFormProps = {
    activeForm: boolean;
    setActiveForm: any;

}

const ContactForm = (props: ContactFormProps) => {
    const [payload, setPayload] = useState<any>({})
    const [isValidForm, setIsValidForm] = useState(false)

    const onClick = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        };

        fetch(`${process.env.REACT_APP_API}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            props.setActiveForm(false)
            return
        });
    }

    useEffect(() => {
        if (payload.company_email && payload.name && payload.message) {
            setIsValidForm(true)
        } else {
            setIsValidForm(false)
        }
    }, [payload])

    return (
        <div className="ContactFormContainer" style={{zIndex: props.activeForm ? 2 : -1, display: props.activeForm ? 'block' : 'none'}}>
            <div className="ContactForm">
                <div className="FormTitle">Contact Us</div>
                <Name setPayload={setPayload} payload={payload}/>
                <CompanyEmail setPayload={setPayload} payload={payload}/>

                <Title setPayload={setPayload} payload={payload}/>
                <CompanyName setPayload={setPayload} payload={payload}/>
                <CompanyAddress setPayload={setPayload} payload={payload}/>
                <Message setPayload={setPayload} payload={payload}/>
                <div className="FormSubmitButtonContainer">
                    <button className="FormSubmitButton" onClick={onClick} disabled={!isValidForm}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContactForm