

const Title = ({payload, setPayload}: any) => {
    const onBlur = (e: any) => {
        setPayload({...payload, title: e.target.value})
    }

    return (
        <div>
            <div className="InputLabel">Title</div>
            <input type="text" placeholder="Enter Title" className="FormInput" onBlur={onBlur}/>
        </div>
    )
}

export default Title