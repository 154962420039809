

const Name = ({payload, setPayload}: any) => {
    const onBlur = (e: any) => {
        setPayload({...payload, name: e.target.value})
    }

    return (
        <div>
            <div className="InputLabel">Name<span style={{fontSize: '11px', float: 'right'}}>*Required</span></div>
            <input type="text" placeholder="Enter Name" className="FormInput" onBlur={onBlur}/>
        </div>
    )
}

export default Name