import './App.css';
import { HeaderBar } from './components/HeaderBar';
import { ContactForm } from './components/ContactForm';
import { useState } from 'react';

function App() {
  const [activeForm, setActiveForm] = useState(false)

  return (
    <div >
      <HeaderBar setActiveForm={setActiveForm} activeForm={activeForm}/>
      
      <div className='FooterSectionContainer' style={{zIndex: activeForm ? -1 : 0, display: activeForm ? 'none' : 'block'}}>
        <div className='FooterSection'>
            <div className='FooterTitle'>Building the largest global <span className='PurePlay'>pure-play</span> <span className='AI'>AI</span> <br/><span className='Infrastructure'>infrastructure</span></div>
            <div>
              <img src="/img/logoFull.png" className="FooterLogo"/>
            </div>
        </div>
      </div>
      <ContactForm activeForm={activeForm} setActiveForm={setActiveForm}/>
      <div className='FooterContainer'>
        <div className='FooterContent'>© Global AI All Rights Reserved</div>
      </div>
    </div>
  );
}

export default App;
