

const CompanyName = ({payload, setPayload}: any) => {
    const onBlur = (e: any) => {
        setPayload({...payload, company_name: e.target.value})
    }
    return (
        <div>
            <div className="InputLabel">Company</div>
            <input type="text" placeholder="Enter Company" className="FormInput" onBlur={onBlur}/>
        </div>
    )
}

export default CompanyName